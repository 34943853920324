<template>
  <v-row v-if="NOTARIA">
    <!-- Start Blog Area  -->
    <v-col :cols=comNumColumnas v-for="(item, i) in NOTARIA.Blog" :key="i">
      <div :class="'blog blog-style-notin card-' + type + '-notin'"
        v-if="((item.MostrarHome && item.Imagen) || !Home) && item.Publicado">
        <div class="thumbnail">
          <NuxtLink :to="'/blog/' + item.Slug">
            <img class="w-100" :src="imagenIDtoSRC(item.Imagen)" :alt="'Imagen de la entrada: ' + item.Titulo" />
          </NuxtLink>
        </div>
        <div class="content">
          <!-- <p class="blogtype">{{ item.Extracto }}</p> -->
          <h3 class="heading-title">
            <NuxtLink :to="'/blog/' + item.Slug">{{ item.Titulo }}</NuxtLink>
          </h3>
          <div class="blog-btn">
            <NuxtLink :to="'/blog/' + item.Slug" class="rn-btn-notin"
              :title="'Leer la entrada, ' + item.Titulo + ', completa.'">
              Leer Más
            </NuxtLink>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Blog Area  -->
  </v-row>
</template>
<script setup lang="ts">
import { useStore } from '../store/index'
const { NOTARIA } = useStore()
import { useUtils } from '#imports';
const { imagenIDtoSRC, $t } = useUtils()
const props = defineProps({
  Home: Boolean,
  type: String
})
const comNumColumnas = computed(()=> {
    if (NOTARIA.Theme.Blog.NumeroColumnas == 1) {
      return 12
    } else if (NOTARIA.Theme.Blog.NumeroColumnas == 2) {
      return 6
    } else if (NOTARIA.Theme.Blog.NumeroColumnas == 3) {
      return 4
    } else if (NOTARIA.Theme.Blog.NumeroColumnas == 4) {
      return 3
    }
  })
</script>